
#id668c57d4914da0d427fe9da5 {
  #i3cg {
min-height : 100px; 
font-family : Helvetica, sans-serif; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#f2f7fe 0%, #f2f7fe 100%); 
} 
#izg5 {
padding : 80px 2% 80px 2%; 
min-height : 100px; 
} 
#iat5x {
padding : 90px 5% 20px 5%; 
min-height : 100px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(white 0%, white 100%); 
color : #000000; 
display : flex; 
justify-content : space-between; 
flex-wrap : wrap; 
} 
.href_button {
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
text-align : center; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
} 
.href_button:disabled  {
background-color : rgb(226, 229, 222); 
color : gray; 
} 
.href_button:hover  {
background-image : linear-gradient(rgb(81, 169, 238), rgb(20, 123, 205)); 
border-top-color : rgb(20, 130, 208); 
border-right-color : rgb(20, 130, 208); 
border-bottom-color : rgb(20, 130, 208); 
border-left-color : rgb(20, 130, 208); 
color : rgb(255, 255, 255); 
} 
.href_button:active  {
background-image : radial-gradient(circle at 24.1% 68.8%, rgb(50, 50, 50) 0%, rgb(0, 0, 0) 99.4%); 
border-top-color : rgb(0, 109, 188); 
border-right-color : rgb(0, 109, 188); 
border-bottom-color : rgb(0, 109, 188); 
border-left-color : rgb(0, 109, 188); 
color : rgb(255, 255, 255); 
} 
.botonprincipal {
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#50c10a 0%, #50c10a 100%); 
color : #ffffff; 
text-decoration : none; 
padding : 10px 30px 10px 30px; 
border-radius : 25px 25px 25px 25px; 
} 
.botonprincipal:hover  {
color : #ffffff; 
text-decoration : none; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#439b0c 0%, #439b0c 100%); 
} 
.botonsecundario {
padding : 10px 30px 10px 30px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#fdac2b 0%, #fdac2b 100%); 
color : #ffffff; 
text-decoration : none; 
border-radius : 25px 25px 25px 25px; 
} 
.botonsecundario:hover  {
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#d48d1c 0%, #d48d1c 100%); 
color : #ffffff; 
text-decoration : none; 
} 
.tajerta {
width : 250px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#fbfcff 0%, #fbfcff 100%); 
box-shadow : 0px 0px 5px 0px rgba(0,0,0,0.12) ; 
margin : 10px 10px 10px 10px; 
padding : 50px 25px 50px 25px; 
border-radius : 30px 30px 30px 30px; 
} 
.tajerta:hover  {
box-shadow : 0 0 5px 0 rgba(0,0,0,0.58) ; 
} 
.item {
height : 200px; 
margin-top : 50px; 
margin-right : auto; 
margin-bottom : 50px; 
margin-left : auto; 
padding-top : 75px; 
text-align : center; 
} 
.gjs-container {
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
min-height : 100px; 
display : block; 
} 
#ijcy9b {
padding : 10px; 
min-height : 100px; 
} 
#ise2r9 {
padding : 10px; 
min-height : 100px; 
display : flex; 
flex-wrap : wrap; 
justify-content : space-around; 
} 
#i0qriz {
padding : 10px; 
min-height : 100px; 
} 
#i14ubl {
padding : 10px; 
display : block; 
font-weight : 600; 
} 
#icpwci {
padding : 10px; 
display : block; 
} 
#i8hlod {
padding : 10px; 
min-height : 100px; 
font-size : 14px; 
} 
#i0vnkg {
padding : 10px; 
display : block; 
} 
#i7jvdl {
padding : 10px; 
display : block; 
font-weight : 600; 
} 
#ic9ies {
padding : 10px; 
display : block; 
font-weight : 600; 
} 
#icjl6l {
padding : 10px; 
display : block; 
} 
#io7ssq {
padding : 10px; 
display : block; 
} 
#idmvel {
padding : 10px; 
min-height : 100px; 
font-size : 14px; 
} 
#ieb919 {
padding : 10px; 
min-height : 100px; 
} 
#i5kwyk {
padding : 10px; 
display : block; 
font-weight : 600; 
} 
#izt1ml {
padding : 10px; 
display : block; 
} 
#ip0pg2 {
padding : 10px; 
display : block; 
} 
#ijr81i {
padding : 10px; 
min-height : 100px; 
font-size : 14px; 
color : black; 
} 
#ia2by2 {
padding : 10px; 
min-height : 100px; 
} 
#ipa2qp {
padding : 10px; 
min-height : 100px; 
border : 1px solid #f37604; 
border-radius : 30px 30px 30px 30px; 
width : 695.0574999999999px; 
} 
#ixz1b7 {
padding : 10px; 
text-align : right; 
width : 100%; 
} 
#i4aa3m {
padding : 10px; 
display : block; 
} 
#iiv8pp {
padding : 10px; 
display : block; 
} 
#i4plpg {
padding : 10px; 
display : block; 
} 
.tarjeta {
width : 380px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffffff 0%, #ffffff 100%); 
border-radius : 30px 30px 30px 30px; 
padding : 60px 10px 60px 10px; 
} 
.linea-tarjeta {
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#d8e0eb 0%, #d8e0eb 100%); 
width : 2px; 
} 
#iw4tpx {
padding : 10px; 
min-height : 20vh; 
display : flex; 
flex-wrap : wrap; 
justify-content : space-between; 
align-items : center; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffffff 0%, #ffffff 100%); 
} 
#iz999t {
__background-type : image; 
background-repeat : no-repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : cover; 
background-image : url('https://assetsprojects.s3.amazonaws.com/eewk5ilwi2c5g9.png'); 
justify-content : space-around; 
align-items : center; 
} 
#i7xb8r {
min-height : 100px; 
display : block; 
} 
#iw2km3 {
color : black; 
width : 180px; 
} 
#i282zh {
padding : 10px; 
min-height : 100px; 
width : 480px; 
display : flex; 
flex-wrap : wrap; 
align-items : center; 
justify-content : space-around; 
} 
#iucead {
padding : 10px; 
min-height : 100px; 
display : flex; 
justify-content : flex-end; 
align-items : center; 
} 
#igkctr {
padding : 5px; 
width : auto; 
height : auto; 
display : block; 
} 
#im43t5 {
width : auto; 
min-height : 10px; 
padding : 5px 5px 5px 5px; 
} 
.link-nabvar {
text-decoration : none; 
color : #024c99; 
font-size : 20px; 
} 
.link-nabvar:hover  {
color : #959595; 
} 
#id5l3e {
width : auto; 
min-height : 10px; 
padding : 5px 5px 5px 5px; 
} 
#iugyy5 {
padding : 5px; 
width : auto; 
height : auto; 
display : block; 
} 
#io4byk {
width : auto; 
min-height : 10px; 
padding : 5px 5px 5px 5px; 
} 
#iibluu {
padding : 5px; 
width : auto; 
height : auto; 
display : block; 
} 
#i4h4c3 {
width : auto; 
min-height : 10px; 
padding : 5px 5px 5px 5px; 
} 
#iajvrv {
padding : 5px; 
width : auto; 
height : auto; 
display : block; 
} 
#inoz1l {
display : inline-block; 
min-width : 50px; 
cursor : pointer; 
text-align : center; 
} 
#imdyke {
display : block; 
padding : 10px 10px 10px 10px; 
margin : 0px 15px 0px 15px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(rgba(2,76,153,0.29) 0%, rgba(2,76,153,0.29) 100%); 
} 
#idnkr7 {
color : black; 
} 
#ixrczl {
color : black; 
} 
#io94k5 {
display : block; 
margin : 0px 15px 0px 15px; 
padding : 10px 10px 10px 10px; 
} 
.boton-lineal-azul {
border-radius : 28px 28px 28px 28px; 
border : 1px solid #024c99; 
text-decoration : none; 
color : #024c99; 
width : 175px; 
align-items : center; 
font-size : 20px; 
padding : 14px 20px 14px 20px; 
} 
.boton-lineal-azul:hover  {
color : white; 
text-decoration : none; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#024c99 0%, #024c99 100%); 
} 
.boton-lineal-naranja {
border : 1px solid #f37900; 
border-radius : 28px 28px 28px 28px; 
color : #f37900; 
text-decoration : none; 
width : 200px; 
text-align : center; 
padding : 15px 20px 15px 20px; 
} 
.boton-lineal-naranja:hover  {
color : white; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#f37900 0%, #f37900 100%); 
} 
.title-imacem {
font-size : 60px; 
font-weight : 800; 
color : #777777; 
} 
.titulo-seccion {
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ebebeb 0%, #ebebeb 100%); 
border-radius : 30px 30px 30px 30px; 
display : flex; 
justify-content : space-between; 
align-items : center; 
padding : 10px 20px 10px 20px; 
} 
.titulo-seccion-imacem {
font-size : 50px; 
font-weight : 700; 
color : #024c99; 
} 
.subtitulo-seccion-imacem {
font-size : 35px; 
color : #024c99; 
font-weight : 300; 
} 
.boton-lineal-azul.large-button {
width : 280px; 
text-align : center; 
} 
.titulo-seccion-secundario {
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ebebeb 0%, #ebebeb 100%); 
border-radius : 30px 30px 30px 30px; 
display : flex; 
justify-content : space-between; 
align-items : center; 
padding : 15px 20px 15px 20px; 
margin : 20px 0px 20px 0px; 
} 
.titulo-seccion-secudario {
color : #024c99; 
font-size : 50px; 
font-weight : 700; 
} 
.titulo-tarjeta {
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#eeeeee 0%, #eeeeee 100%); 
border-radius : 5px 5px 5px 5px; 
font-size : 25px; 
text-align : center; 
font-weight : 600; 
} 
.descripcion-tarjeta {
color : #959595; 
margin : 25px 0px 0px 0px; 
} 
.descripcion-seccion-2 {
color : #024c99; 
font-size : 35px; 
font-weight : 300; 
} 
.tarjeta-blog {
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(white 0%, white 100%); 
border : 1px solid rgba(240,90,136,0.7); 
border-radius : 30px 30px 30px 30px; 
width : 350px; 
} 
#ighxck {
padding : 10px; 
min-height : 100px; 
} 
#inqivl {
color : black; 
width : 400px; 
} 
#ifnuqh {
display : none; 
} 
.contentwrapperanimated {
width : 50px; 
height : 50px; 
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
display : none; 
flex-direction : row; 
justify-content : center; 
align-content : center; 
align-items : center; 
} 
.container_animated {
display : flex; 
flex-direction : column; 
justify-content : center; 
align-content : center; 
align-items : center; 
width : 90%; 
height : 90%; 
} 
.menu__box {
display : block; 
position : fixed; 
top : 0px; 
left : -100%; 
width : 300px; 
height : 100vh; 
margin-top : 0px; 
margin-right : 0px; 
margin-bottom : 0px; 
margin-left : 0px; 
padding-top : 80px; 
padding-right : 0px; 
padding-bottom : 80px; 
padding-left : 0px; 
list-style-position : initial; 
list-style-image : initial; 
list-style-type : none; 
background-color : rgb(214, 164, 25); 
box-shadow : rgba(0, 0, 0, 0.4) 2px 2px 6px; 
transition-duration : 0.25s; 
z-index : 1; 
} 
.menu__item {
display : block; 
padding-top : 12px; 
padding-right : 24px; 
padding-bottom : 12px; 
padding-left : 24px; 
text-align : center; 
color : black; 
font-family : Roboto, sans-serif; 
font-size : 20px; 
font-weight : 600; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
transition-duration : 0.25s; 
} 
.menu__item:hover  {
background-color : rgb(207, 216, 220); 
} 
.menu__btn {
display : flex; 
flex-direction : column; 
position : absolute; 
align-items : center; 
cursor : pointer; 
width : 30px; 
height : 40px; 
z-index : 2; 
} 
.span_middle {
display : block; 
position : relative; 
width : 25px; 
height : 2px; 
margin-top : 1px; 
margin-bottom : 1px; 
background-color : rgb(214, 164, 25); 
transition-duration : 0.25s; 
} 
.span_before {
display : block; 
position : relative; 
width : 25px; 
height : 2px; 
margin-top : 1px; 
margin-bottom : 1px; 
background-color : rgb(214, 164, 25); 
transition-duration : 0.25s; 
} 
.span_after {
display : block; 
position : relative; 
width : 25px; 
height : 2px; 
margin-top : 1px; 
margin-bottom : 1px; 
background-color : rgb(214, 164, 25); 
transition-duration : 0.25s; 
} 
.menu__close__btn {
display : flex; 
flex-direction : column; 
position : absolute; 
align-items : center; 
cursor : pointer; 
width : 25px; 
height : 30px; 
left : -100%; 
z-index : 2; 
} 
.span__close__left {
display : block; 
position : relative; 
width : 100%; 
height : 2px; 
margin-top : 0px; 
margin-bottom : 0px; 
background-color : black; 
transform : rotate(45deg); 
} 
.span__close__right {
display : block; 
position : relative; 
width : 100%; 
height : 2px; 
top : 0px; 
margin-top : 0px; 
margin-bottom : 0px; 
background-color : black; 
transform : rotate(135deg); 
} 
.linkhrefs {
position : fixed; 
} 
#iwj18p {
padding : 10px 2% 10px 2%; 
width : 100%; 
min-height : 320px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(rgba(22,87,159,0.5) 0%, rgba(22,87,159,0.5) 100%); 
display : flex; 
align-items : center; 
} 
#izpzwf {
min-height : 100px; 
padding : 10px; 
font-size : 45px; 
color : white; 
font-weight : 600; 
} 
#i8qky5 {
padding : 10px 10px 10px 10px; 
} 
.crudContainerDB {
width : 100%; 
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
} 
.input {
width : 100%; 
padding : 15px 20px 15px 20px; 
border-radius : 28px 28px 28px 28px; 
border : 1px solid #024c99; 
color : #024c99; 
} 
.vinculo-negro {
color : black; 
text-decoration : none; 
min-height : 10px; 
} 
#iif17w {
padding : 10px; 
min-height : 100px; 
} 
#i0jv0l {
padding : 10px; 
min-height : 100px; 
} 
.tarjeta.full-ancho {
width : 100%; 
} 
.tarjeta.tarjeta-busqueda {
width : 100%; 
display : flex; 
align-items : center; 
justify-content : flex-end; 
} 
#ihqfm2 {
padding : 10px; 
width : 683.79296875px; 
display : flex; 
flex-wrap : wrap; 
justify-content : space-between; 
} 
.inputContainer {
width : 453.171875px; 
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
} 
.formInput {
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
margin-bottom : 10px; 
background-image : initial; 
background-position-x : initial; 
background-position-y : initial; 
background-size : initial; 
background-repeat : initial; 
background-attachment : initial; 
background-origin : initial; 
background-clip : initial; 
background-color : transparent; 
border-top-width : initial; 
border-right-width : initial; 
border-left-width : initial; 
border-top-style : none; 
border-right-style : none; 
border-left-style : none; 
border-top-color : initial; 
border-right-color : initial; 
border-left-color : initial; 
border-image-source : initial; 
border-image-slice : initial; 
border-image-width : initial; 
border-image-outset : initial; 
border-image-repeat : initial; 
border-bottom-width : 1px; 
border-bottom-style : solid; 
border-bottom-color : rgb(0, 0, 0); 
width : 100%; 
} 
#isfaby {
width : 100%; 
padding : 10px 10px 10px 10px; 
border-radius : 10px 10px 10px 10px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#f2f7fe 0%, #f2f7fe 100%); 
border : 1px solid #024c99; 
font-size : 20px; 
} 
.standard_button {
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
text-align : center; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
display : inline-block; 
background-image : radial-gradient(circle at 10% 20%, rgb(226, 37, 37) 0%, rgb(211, 49, 49) 82.8%); 
border-top-width : 1px; 
border-right-width : 1px; 
border-bottom-width : 1px; 
border-left-width : 1px; 
border-top-style : solid; 
border-right-style : solid; 
border-bottom-style : solid; 
border-left-style : solid; 
border-top-color : rgb(0, 119, 204); 
border-right-color : rgb(0, 119, 204); 
border-bottom-color : rgb(0, 119, 204); 
border-left-color : rgb(0, 119, 204); 
border-image-source : initial; 
border-image-slice : initial; 
border-image-width : initial; 
border-image-outset : initial; 
border-image-repeat : initial; 
font-size : 14px; 
border-top-left-radius : 5px; 
border-top-right-radius : 5px; 
border-bottom-right-radius : 5px; 
border-bottom-left-radius : 5px; 
margin-top : 4px; 
margin-right : 2px; 
margin-bottom : 4px; 
margin-left : 2px; 
cursor : pointer; 
color : rgb(255, 255, 255); 
} 
.standard_button:disabled  {
background-color : rgb(226, 229, 222); 
color : gray; 
} 
.standard_button:hover  {
background-image : linear-gradient(rgb(81, 169, 238), rgb(20, 123, 205)); 
border-top-color : rgb(20, 130, 208); 
border-right-color : rgb(20, 130, 208); 
border-bottom-color : rgb(20, 130, 208); 
border-left-color : rgb(20, 130, 208); 
color : rgb(255, 255, 255); 
} 
.standard_button:active  {
background-image : radial-gradient(circle at 24.1% 68.8%, rgb(50, 50, 50) 0%, rgb(0, 0, 0) 99.4%); 
border-top-color : rgb(0, 109, 188); 
border-right-color : rgb(0, 109, 188); 
border-bottom-color : rgb(0, 109, 188); 
border-left-color : rgb(0, 109, 188); 
color : rgb(255, 255, 255); 
} 
#ihg9wf {
min-height : 10px; 
} 
.imgDynamics {
width : 100px; 
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
} 
#imdyke:hover  {
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(rgba(2,76,153,0.29) 0%, rgba(2,76,153,0.29) 100%); 
} 
#io94k5:hover  {
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(rgba(2,76,153,0.29) 0%, rgba(2,76,153,0.29) 100%); 
} 
#ibidnu {
min-height : 100px; 
padding : 10px; 
display : flex; 
flex-wrap : wrap; 
} 
#ilc3k4 {
padding : 10px; 
margin : 25px 25px 25px 25px; 
} 
#ibc7eh {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
} 
#i6t1l6 {
padding : 5px; 
margin-top : 5px; 
margin-bottom : 5px; 
} 
#iiei6l {
padding : 10px; 
min-height : 100px; 
} 
#ise1o1 {
padding : 10px; 
height : 220px; 
border-radius : 30px 30px 30px 30px; 
overflow : hidden; 
} 
#i89tvv {
padding : 5px; 
width : 100%; 
height : auto; 
border-radius : 30px 30px 30px 30px; 
} 
#ihic5j {
padding : 10px; 
} 
#i9tfpi {
width : 100%; 
display : block; 
padding : 10px 10px 10px 10px; 
color : #000000; 
} 

  @media only screen and (max-width: 992px) {.tajerta {
margin : 10px 10px 10px 10px; 
width : 250px; 
}} 
@media only screen and (max-width: 992px) {#izg5 {
padding : 80px 10px 80px 10px; 
}} 
@media only screen and (max-width: 992px) {#iat5x {
padding : 10px 10px 10px 10px; 
}} 
@media only screen and (max-width: 992px) {#i0qriz {
width : 239.09088134765625px; 
padding : 10px 0px 10px 0px; 
}} 
@media only screen and (max-width: 992px) {#ia2by2 {
width : 161.81817626953125px; 
padding : 10px 0px 10px 0px; 
}} 
@media only screen and (max-width: 992px) {#ieb919 {
width : 171.8182373046875px; 
padding : 10px 0px 10px 0px; 
}} 
@media only screen and (max-width: 992px) {.tarjeta {
width : 220px; 
margin : 5px 5px 5px 5px; 
}} 
@media only screen and (max-width: 992px) {#iw4tpx {
flex-wrap : nowrap; 
min-height : 10vh; 
}} 
@media only screen and (max-width: 992px) {#i282zh {
width : 382px; 
}} 
@media only screen and (max-width: 992px) {.title-imacem {
font-size : 40px; 
}} 
@media only screen and (max-width: 992px) {.link-nabvar {
font-size : 16px; 
}} 
@media only screen and (max-width: 992px) {.boton-lineal-azul {
font-size : 16px; 
width : 150px; 
}} 
@media only screen and (max-width: 992px) {#iw2km3 {
width : 100px; 
}} 
@media only screen and (max-width: 992px) {#i7xb8r {
display : flex; 
align-items : center; 
}} 
@media only screen and (max-width: 992px) {.titulo-seccion-imacem {
font-size : 30px; 
}} 
@media only screen and (max-width: 992px) {.subtitulo-seccion-imacem {
font-size : 20px; 
}} 
@media only screen and (max-width: 992px) {.tarjeta-blog {
width : 220px; 
margin : 5px 5px 5px 5px; 
}} 
@media only screen and (max-width: 992px) {#inqivl {
width : 113.5625px; 
}} 
@media only screen and (max-width: 992px) {#ipa2qp {
width : 604.21875px; 
}} 
@media only screen and (max-width: 992px) {#ighxck {
display : flex; 
align-items : center; 
}} 
@media only screen and (max-width: 992px) {#ise2r9 {
padding : 0px 0px 0px 0px; 
}} 
@media only screen and (max-width: 992px) {.titulo-tarjeta {
font-size : 18px; 
}} 
@media only screen and (max-width: 992px) {#ifnuqh {
display : none; 
}} 
@media only screen and (max-width: 992px) {#ihqfm2 {
width : 635.4609375px; 
}} 
@media only screen and (max-width: 992px) {#ii95xa {
font-size : 12px; 
}} 
@media only screen and (max-width: 992px) {#ise1o1 {
height : 150px; 
}} 

  @media only screen and (max-width: 480px) {.botonprincipal {
display : none; 
}} 
@media only screen and (max-width: 480px) {.tajerta {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#i0qriz {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#iat5x {
padding : 10px 5px 10px 5px; 
justify-content : space-around; 
}} 
@media only screen and (max-width: 480px) {#ia2by2 {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#ieb919 {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {.linea-tarjeta {
display : none; 
}} 
@media only screen and (max-width: 480px) {.tarjeta {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#i282zh {
display : none; 
}} 
@media only screen and (max-width: 480px) {#iucead {
display : none; 
}} 
@media only screen and (max-width: 480px) {#iw2km3 {
display : none; 
}} 
@media only screen and (max-width: 480px) {#ifnuqh {
color : black; 
width : 130px; 
display : block; 
}} 
@media only screen and (max-width: 480px) {.title-imacem {
font-size : 20px; 
}} 
@media only screen and (max-width: 480px) {.boton-lineal-naranja {
font-size : 12px; 
}} 
@media only screen and (max-width: 480px) {#iz999t {
__background-type : image; 
background-repeat : no-repeat; 
background-position : center bottom; 
background-attachment : scroll; 
background-size : cover; 
background-image : url('https://assetsprojects.s3.amazonaws.com/eewk5ilwi2c5g9.png'); 
}} 
@media only screen and (max-width: 480px) {.titulo-seccion {
flex-wrap : wrap; 
flex-direction : column; 
justify-content : space-between; 
align-items : stretch; 
}} 
@media only screen and (max-width: 480px) {.subtitulo-seccion-imacem {
text-align : right; 
}} 
@media only screen and (max-width: 480px) {.boton-lineal-azul.large-button {
font-size : 14px; 
}} 
@media only screen and (max-width: 480px) {#ijcy9b {
padding : 10px 0px 10px 0px; 
}} 
@media only screen and (max-width: 480px) {.tarjeta-blog {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#inqivl {
width : 80%; 
}} 
@media only screen and (max-width: 480px) {#ighxck {
width : 100%; 
justify-content : center; 
}} 
@media only screen and (max-width: 480px) {#i188q6 {
padding : 10px; 
min-height : 100px; 
width : 65.80078125px; 
}} 
@media only screen and (max-width: 480px) {.contentwrapperanimated {
display : block; 
}} 
@media only screen and (max-width: 480px) {#iwj18p {
min-height : 200px; 
}} 
@media only screen and (max-width: 480px) {#izpzwf {
font-size : 30px; 
}} 
@media only screen and (max-width: 480px) {#izg5 {
padding : 50px 10px 50px 10px; 
}} 
@media only screen and (max-width: 480px) {#ihqfm2 {
width : 252.08606250000003px; 
justify-content : center; 
}} 
@media only screen and (max-width: 480px) {.tarjeta.tarjeta-busqueda {
flex-wrap : wrap; 
width : 279.76953125px; 
}} 
@media only screen and (max-width: 480px) {.inputContainer {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#isfaby {
font-size : 16px; 
}} 
@media only screen and (max-width: 480px) {.boton-lineal-azul.margenesbutton {
margin : 20px 0 0 0; 
}} 
@media only screen and (max-width: 480px) {#iif17w {
padding : 10px 0px 10px 0px; 
}} 

}
  