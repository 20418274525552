
#id66282499e5bd9e8c7add2cf9 {
  #i3cg {
min-height : 100vh; 
font-family : Helvetica, sans-serif; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#f2f7fe 0%, #f2f7fe 100%); 
} 
#i2ow {
padding : 80px 10% 80px 10%; 
display : flex; 
align-items : center; 
justify-content : center; 
} 
.href_button {
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
text-align : center; 
text-decoration-line : none; 
text-decoration-thickness : initial; 
text-decoration-style : initial; 
text-decoration-color : initial; 
} 
.href_button:disabled  {
background-color : rgb(226, 229, 222); 
color : gray; 
} 
.href_button:hover  {
background-image : linear-gradient(rgb(81, 169, 238), rgb(20, 123, 205)); 
border-top-color : rgb(20, 130, 208); 
border-right-color : rgb(20, 130, 208); 
border-bottom-color : rgb(20, 130, 208); 
border-left-color : rgb(20, 130, 208); 
color : rgb(255, 255, 255); 
} 
.href_button:active  {
background-image : radial-gradient(circle at 24.1% 68.8%, rgb(50, 50, 50) 0%, rgb(0, 0, 0) 99.4%); 
border-top-color : rgb(0, 109, 188); 
border-right-color : rgb(0, 109, 188); 
border-bottom-color : rgb(0, 109, 188); 
border-left-color : rgb(0, 109, 188); 
color : rgb(255, 255, 255); 
} 
.botonprincipal {
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#50c10a 0%, #50c10a 100%); 
color : #ffffff; 
text-decoration : none; 
padding : 10px 30px 10px 30px; 
border-radius : 25px 25px 25px 25px; 
} 
.botonprincipal:hover  {
color : #ffffff; 
text-decoration : none; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#439b0c 0%, #439b0c 100%); 
} 
.botonsecundario {
padding : 10px 30px 10px 30px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#fdac2b 0%, #fdac2b 100%); 
color : #ffffff; 
text-decoration : none; 
border-radius : 25px 25px 25px 25px; 
} 
.botonsecundario:hover  {
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#d48d1c 0%, #d48d1c 100%); 
color : #ffffff; 
text-decoration : none; 
} 
#ibmh3 {
padding : 10px; 
display : block; 
text-align : center; 
font-size : 5rem; 
font-weight : 700; 
} 
.tajerta {
width : 300px; 
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#fbfcff 0%, #fbfcff 100%); 
box-shadow : 0px 0px 5px 0px rgba(0,0,0,0.12) ; 
} 
.tajerta:hover  {
box-shadow : 0 0 5px 0 rgba(0,0,0,0.58) ; 
} 
.item {
height : 200px; 
margin-top : 50px; 
margin-right : auto; 
margin-bottom : 50px; 
margin-left : auto; 
padding-top : 75px; 
text-align : center; 
} 
.gjs-container {
padding-top : 10px; 
padding-right : 10px; 
padding-bottom : 10px; 
padding-left : 10px; 
min-height : 100px; 
display : block; 
} 
#i01ugh {
padding : 10px; 
min-height : 100px; 
} 
.crudContainerDB {
width : 100%; 
padding-top : 5px; 
padding-right : 5px; 
padding-bottom : 5px; 
padding-left : 5px; 
} 
.filainput {
display : flex; 
justify-content : space-around; 
} 
.inputcolumn {
width : 50%; 
display : flex; 
align-items : center; 
} 
.inputimacem {
__background-type : color; 
background-repeat : repeat; 
background-position : left top; 
background-attachment : scroll; 
background-size : auto; 
background-image : linear-gradient(#ffffff 0%, #ffffff 100%); 
padding : 10px 10px 10px 10px; 
border-radius : 10px 10px 10px 10px; 
} 
.inputimacem.margenizquierda {
margin : 0 0 0 15px; 
} 
.inputimacem.inputfull {
width : 100%; 
} 
#ihhgn {
color : black; 
width : 190.3653491210937px; 
min-height : 10px; 
} 
#il0ii {
padding : 10px; 
display : flex; 
justify-content : space-around; 
} 
#i2l21 {
padding : 10px; 
text-align : center; 
min-width : 50px; 
min-height : 30px; 
cursor : pointer; 
color : #fff; 
} 
.botonprincipal.botoncontacto {
width : 250px; 
} 

  @media only screen and (max-width: 992px) {.tajerta {
margin : 10px 10px 10px 10px; 
width : 200px; 
}} 
@media only screen and (max-width: 992px) {#i2ow {
padding : 80px 10px 80px 10px; 
}} 
@media only screen and (max-width: 992px) {#ibmh3 {
font-size : 3rem; 
}} 

  @media only screen and (max-width: 480px) {.botonprincipal {
display : none; 
}} 
@media only screen and (max-width: 480px) {#ibmh3 {
font-size : 1.8rem; 
}} 
@media only screen and (max-width: 480px) {.tajerta {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {#i2ow {
padding : 80px 0px 80px 0px; 
}} 
@media only screen and (max-width: 480px) {.filainput {
padding : 10px 0px 10px 0px; 
flex-wrap : wrap; 
}} 
@media only screen and (max-width: 480px) {.inputcolumn {
width : 100%; 
}} 
@media only screen and (max-width: 480px) {.botonprincipal.botonmovil {
display : block; 
}} 
@media only screen and (max-width: 480px) {.botonprincipal.botoncontacto {
display : block; 
}} 

}
  